import { VisitorConsentCollectedEvent } from '@integrabeauty/shopify-browser-types';
import { hasMarketingConsent } from './shopify-consent.js';

function appendScriptToHead() {
  let script = <HTMLScriptElement>document.getElementById('postpilot-pixel');
  if (script) {
    return;
  }

  script = document.createElement('script');
  script.id = 'postpilot-pixel';
  script.src = 'https://xp2023-pix.s3.amazonaws.com/px_4eFeW.js';
  script.async = true;

  document.head.appendChild(script);
}

function removeScriptFromHead() {
  const script = document.getElementById('postpilot-pixel');
  if (script) {
    script.parentNode.removeChild(script);
  }
}

function onVisitorConsentCollected(event: VisitorConsentCollectedEvent) {
  if (event.detail.marketingAllowed) {
    appendScriptToHead();
  } else {
    removeScriptFromHead();
  }
}

if (hasMarketingConsent()) {
  // Load the tracking script
  appendScriptToHead();
}

document.addEventListener('visitorConsentCollected', onVisitorConsentCollected);
